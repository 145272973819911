import $ from 'jquery';
// import Raven from 'raven-js';
import _ from 'lodash';
import Tether from 'tether';
import WOW from 'wowjs';

window._ = _;
// window.Raven = Raven;
window.jQuery = $; window.$ = $;
window.Tether = Tether;
window.WOW = WOW;
require('owl.carousel')
require('bootstrap');

